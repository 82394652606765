import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { UnmountClosed } from 'react-collapse';
import { usePrevious } from 'react-use';

import {
  authenticateUser,
  getUser,
  signOut,
  sendResetPasswordEmail,
  sendWelcomeEmail,
  getAuthStatusForEmail,
  PLATFORM_AUTH_PROVIDERS,
} from 'lib/auth';
import { createUser, getPrivateProfile } from 'lib/db';
import { trackError } from 'lib/tracking';
import { isValidEmail } from 'lib/utils/string';
import { MOBILE_APP } from 'lib/env';

import { prompts, toasts } from 'components/alert';
import Modal from 'components/Modal';
import Link from 'components/Link';
import Field from 'components/form/Field';
import SocialIcon from 'components/social/SocialIcon';
import { LoadingCar } from 'components/landing/LandingImages';
import RefCodeModal from 'components/form/RefCodeModal';

const signInSchema = z.object({
  email: z.string().nonempty('Required').email(),
  password: z.string().nonempty('Required'),
});

const signUpSchema = z
  .object({
    email: z.string().email(),
    password: z.string().min(8),
    confirm_password: z.string(),
    first_name: z.string().nonempty('Required'),
    last_name: z.string().nonempty('Required'),
  })
  .refine((vals) => vals.password === vals.confirm_password, {
    message: "Passwords don't match",
    path: ['confirm_password'],
  });

const LoginProviderButton = ({
  provider: { buttonStyles, socialIcon, name, key: providerId },
  newUserPage,
  onClick,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <button
      type="button"
      disabled={loading}
      className={clsx('button is-fullwidth has-text-weight-bold', loading && 'is-loading')}
      style={buttonStyles}
      onClick={async () => {
        setLoading(true);
        await onClick(providerId);
        setLoading(false);
      }}
    >
      <SocialIcon icon={socialIcon} size={18} iconStyle={{ marginRight: 8 }} />
      Sign {newUserPage ? 'up' : 'in'} with {name}
      <style jsx>{`
        button {
          background-color: white; // default
          justify-content: flex-start;
          font-size: 0.8rem;
          height: 2.5rem;
          padding-left: 1rem;

          &:hover {
            filter: brightness(93%);
          }
        }
      `}</style>
    </button>
  );
};

export const LoginForm = ({
  onLoggedIn,
  onClose,
  newUserPage,
  fromPath,
  setNewUserPage,
  fullPage = false,
  loginOnly = false,
  customTitle = null,
  customTerms = undefined,
  showLoginOnBottom = false,
}) => {
  const [loading, setLoading] = useState(false);

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      confirm_password: '',
      first_name: '',
      last_name: '',
      // phone: '' // TODO-loginpass: enter phone during login
    },
    resolver: useMemo(() => zodResolver(newUserPage ? signUpSchema : signInSchema), [newUserPage]),
  });

  const [showPassword, setShowPassword] = useState(false);

  const [showReferralModal, setShowReferralModal] = useState(false);

  const emailValue = useWatch({ name: 'email', control: form.control });
  const prevEmail = usePrevious(emailValue);
  const resetForm = form.reset;
  useEffect(() => {
    // reset form when user change's their email after clicking "Continue"
    if (prevEmail && emailValue !== prevEmail && showPassword) {
      setShowPassword(false);
      resetForm(
        { email: emailValue },
        {
          keepDirty: true,
        },
      );
    }
  }, [resetForm, emailValue, prevEmail, showPassword]);

  const toggleMode = () => {
    setShowPassword(false);
    setNewUserPage(!newUserPage);

    // `react-hook-form` caches the `resolver` argument internally, so we need to
    // break that cache here so we can change our form's schema
    form.reset(form.getValues());
  };

  const openReferralModal = () => {
    setShowReferralModal(true);
  };
  const closeReferralModal = () => {
    setShowReferralModal(false);
  };

  const handleAuthenticateSuccess = async (
    signInData,
    formValues,
    isNewUserWithPassword = false,
  ) => {
    const authUser = getUser();

    let privateProfile = await getPrivateProfile(authUser.uid, {
      fail: false,
    });

    if (!privateProfile) {
      // for provider login (google/facebook), these values will probably be blank,
      // so we'll use `authUser.displayName` on the backend to fill in firstName & lastName
      privateProfile = await createUser({
        firstName: formValues.first_name,
        lastName: formValues.last_name,
      });
    }

    signInData = { ...signInData, privateProfile };

    if (!isNewUserWithPassword) await onLoggedIn?.(signInData);

    return signInData;
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    const { email, password } = values;

    try {
      const signInData = await authenticateUser({
        type: 'email_password',
        isNewUser: newUserPage,
        email,
        password,
        // phone: values.phone,
      });

      await handleAuthenticateSuccess(signInData, values, signInData.isNewUser);

      if (signInData.isNewUser) {
        // immediately sign out b/c we want user to verify their email
        await signOut();

        await sendWelcomeEmail(signInData.uid, fromPath);

        form.reset();

        prompts.alert(
          <>
            <p className="is-size-5">
              You should receive a verification email in the email inbox for{' '}
              <strong>{email}</strong>
            </p>
            <br />
            <p>Please click the link in that email to finish signing up.</p>
          </>,
        );

        onClose?.(); // close modal if it's a modal
      }
    } catch (err) {
      toasts.error(err);
      trackError(err);
    }

    setLoading(false);
  };

  const loginWithProvider = async (providerId) => {
    const values = form.getValues();

    try {
      const signInData = await authenticateUser({
        type: 'provider_popup',
        providerId,
      });

      await handleAuthenticateSuccess(signInData, values);
    } catch (err) {
      toasts.error(err);
      trackError(err);
    }
  };

  const continueSignIn = async (e) => {
    e.preventDefault();

    const validEmail = await form.trigger('email');
    if (!validEmail) return;

    setLoading(true);

    const { email } = form.getValues();

    const authStatus = await getAuthStatusForEmail(email);

    if (!authStatus.exists) {
      setLoading(false);
      form.setError('email', {
        type: 'email-account-dne',
        message: 'An account with this email does not exist',
      });
      return;
    }

    if (authStatus.usesPassword) {
      setShowPassword(true);
    } else {
      try {
        await sendResetPasswordEmail(email, true, fromPath);

        prompts.alert(
          <>
            <p className="is-size-5">
              You should receive a &ldquo;setup password&rdquo; email in the email inbox for{' '}
              <strong>{email}</strong>
            </p>
            <br />
            <p>Please click the link in that email to setup a new password.</p>
          </>,
        );
      } catch (err) {
        toasts.error(err);
        trackError(err);
      }
    }

    setLoading(false);

    // form.reset(); ????
  };

  const resetPassword = async () => {
    let email = form.getValues().email;

    let firstAttempt = true;
    do {
      const res = await prompts.prompt(
        <p style={{ marginBottom: '1rem' }}>
          Please {!firstAttempt ? 'enter a valid' : email ? 'confirm your' : 'enter your'} email
          address
        </p>,
        {
          fields: [
            {
              element: (
                <input className="input" type="email" name="email" required defaultValue={email} />
              ),
            },
          ],
        },
      );

      if (res == null) return; // cancel

      email = res.email;

      firstAttempt = false;
    } while (!isValidEmail(email));

    setLoading(true);

    const authStatus = await getAuthStatusForEmail(email);

    if (!authStatus.exists) {
      toasts.error('An account with this email does not exist');
      setLoading(false);
      return;
    }

    try {
      await sendResetPasswordEmail(email, false, fromPath);
    } catch (err) {
      trackError(err, 'send reset-password email', { sent_to_email: email });
      toasts.error(err);
      setLoading(false);
      return;
    }

    setLoading(false);

    await prompts.alert(
      <>
        <p className="is-size-5">
          You should receive a password reset email in the email inbox for <strong>{email}</strong>
        </p>
        <br />
        <p>Please click the link in that email to setup a new password.</p>
      </>,
    );
  };

  if (loading && newUserPage)
    return (
      <div className="columns is-centered">
        <div className="column is-8 has-text-centered">
          <p className="is-size-4 has-text-weight-bold">Creating your account...</p>
          <br />
          <div>
            <LoadingCar style={{ maxWidth: 400 }} />
          </div>
        </div>
      </div>
    );

  const passwordField = (
    <Field
      label="Password"
      name="password"
      showError
      type="password"
      control={form.control}
      autoComplete="true"
    />
  );

  return (
    <>
      <div className="columns is-centered">
        <div className={clsx('column', fullPage && 'is-8')}>
          <h1 className="is-size-3 has-text-centered">
            {customTitle || (!newUserPage ? 'Login to Vanly' : 'Sign up for Vanly')}
          </h1>
          {!customTitle && (
            <p className="has-text-centered has-text-grey">Come explore the Vanly community</p>
          )}

          {MOBILE_APP && !loginOnly ? (
            <div className="has-text-centered">
              <br />
              <button
                type="button"
                className="button is-fullwidth-mobile is-inverted is-link"
                onClick={openReferralModal}
              >
                Enter Referral Code
              </button>
            </div>
          ) : (
            <>
              <br />
              <br />
            </>
          )}

          {!loginOnly && !showLoginOnBottom && (
            <div
              style={{ marginTop: '0.5rem', marginBottom: '1.25rem' }}
              className="has-text-centered"
            >
              <button
                type="button"
                className="button is-fullwidth-mobile is-inverted is-link"
                onClick={toggleMode}
              >
                {!newUserPage ? 'Not a member? Create an account' : 'Already a member? Login here'}
                <i className="fa fa-arrow-right" style={{ marginLeft: '0.35rem' }} />
              </button>
            </div>
          )}

          <div className="columns">
            <div className="column" style={{ paddingTop: '2.5rem' }}>
              {PLATFORM_AUTH_PROVIDERS.map((provider) => (
                <div key={provider.key} style={{ marginBottom: '1.25rem' }}>
                  <LoginProviderButton
                    onClick={loginWithProvider}
                    newUserPage={newUserPage}
                    provider={provider}
                  />
                </div>
              ))}
            </div>

            <div
              className="is-hidden-mobile column is-1 is-relative has-background-grey-light has-text-grey"
              style={{ width: 2, padding: 0, margin: '0 1rem' }}
            >
              <span
                className="py-1"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '-50%',
                  transform: 'translate(-50%, -50%)',
                  background: 'white',
                }}
              >
                or
              </span>
            </div>
            <div
              className="is-hidden-tablet is-size-5 has-text-centered has-text-grey-dark has-text-weight-bold"
              style={{ padding: '16px 0' }}
            >
              OR
            </div>

            <form
              className="column"
              onSubmit={
                !newUserPage && !showPassword ? continueSignIn : form.handleSubmit(handleSubmit)
              }
              id="email_auth"
            >
              {newUserPage && (
                <div className="flex">
                  <Field
                    label="First name"
                    name="first_name"
                    showError
                    control={form.control}
                    style={{ marginRight: '0.75rem' }}
                  />
                  <Field label="Last name" name="last_name" showError control={form.control} />
                </div>
              )}
              <Field
                label="Email"
                name="email"
                showError
                type="email"
                control={form.control}
                autoComplete="true"
              />

              {newUserPage ? (
                passwordField
              ) : (
                <UnmountClosed isOpened={showPassword}>
                  <div style={{ paddingBottom: '0.75rem' }}>{passwordField}</div>
                </UnmountClosed>
              )}

              {newUserPage ? (
                <Field
                  label="Confirm password"
                  name="confirm_password"
                  showError
                  type="password"
                  control={form.control}
                />
              ) : null}

              <div style={{ paddingTop: '1rem', marginBottom: '1rem' }}>
                <button
                  type="submit"
                  className={clsx('button is-link is-fullwidth', loading && 'is-loading')}
                  disabled={loading}
                >
                  {newUserPage ? 'Sign Up' : showPassword ? 'Sign In' : 'Continue'}
                </button>
              </div>

              {!newUserPage && !loginOnly ? (
                <p className="has-text-centered">
                  <button
                    type="button"
                    style={{
                      fontSize: 14,
                    }}
                    className="button-link grey-link"
                    onClick={resetPassword}
                  >
                    I forgot my password
                  </button>
                </p>
              ) : null}

              {customTerms !== undefined ? (
                customTerms
              ) : newUserPage ? (
                <p className="has-text-centered has-text-grey is-size-7">
                  By signing up with Vanly, you agree to our <Link href="/terms">Terms</Link> and{' '}
                  <Link href="/privacy">Privacy Policy</Link>.
                </p>
              ) : null}
            </form>
          </div>

          {!loginOnly && showLoginOnBottom && (
            <div
              style={{ marginTop: '0.5rem', marginBottom: '1.25rem' }}
              className="has-text-centered"
            >
              <button
                type="button"
                className="button is-fullwidth-mobile is-inverted is-link"
                onClick={toggleMode}
              >
                {!newUserPage ? 'Not a member? Create an account' : 'Already a member? Login here'}
                <i className="fa fa-arrow-right" style={{ marginLeft: '0.35rem' }} />
              </button>
            </div>
          )}
        </div>
      </div>

      <RefCodeModal active={showReferralModal} onClose={closeReferralModal} />
    </>
  );
};

const LoginPrompt = ({
  active,
  onComplete,
  isNewUser = true,
  loginOnly,
  fromPath,
  customTitle,
}) => {
  const [newUserPage, setNewUserPage] = useState(isNewUser);

  return (
    <Modal
      active={active}
      onClose={() => {
        onComplete(false);
      }}
      footer={null}
    >
      <LoginForm
        newUserPage={newUserPage}
        setNewUserPage={setNewUserPage}
        fromPath={fromPath}
        customTitle={customTitle}
        loginOnly={loginOnly}
        onLoggedIn={() => {
          onComplete(true);
        }}
        onClose={() => {
          onComplete(false);
        }}
      />
    </Modal>
  );
};

/**
 * @returns Whether or not login succeeded
 */
export const requireLogin = async (fromPath = null) => {
  if (getUser()) return true;

  const loggedIn = await prompts.custom(LoginPrompt, { fromPath });

  return !!loggedIn;
};

export const handleReauthenticationRequest = async (fn) => {
  try {
    const res = await fn();
    return res;
  } catch (err) {
    if (
      err.code === 'auth/requires-recent-login' ||
      err.message === 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN'
    ) {
      const loggedIn = await prompts.custom(LoginPrompt, {
        fromPath: '/',
        customTitle: (
          <span className="is-size-5 is-block">Please login again to confirm this operation.</span>
        ),
        loginOnly: true,
        isNewUser: false,
      });

      if (!loggedIn) {
        throw new Error('Operation canceled');
      }
    } else {
      throw err;
    }
  }
};
