import React from 'react';
import clsx from 'clsx';
import { Control, ControllerRenderProps, FieldError, useController } from 'react-hook-form';
// @ts-expect-error missing types
import { UnmountClosed } from 'react-collapse';
import { usePrevious } from 'react-use';

import { useSequentialId } from 'lib/utils/sequentialId';
import LimitedTextInput from 'components/form/LimitedTextInput';
import { parseNum } from 'lib/utils';
import { Checkbox } from 'components/form/CheckRadio';

const getErrorMessage = (err: { message?: string } | string | null | undefined): string | null => {
  if (typeof err === 'string' && err.length > 0) return err;
  else if (typeof err === 'object' && err !== null) return getErrorMessage(err.message);
  else return null;
};

type WrapperProps = {
  inputId?: string;
  label?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  error?: FieldError;
  isHorizontal?: boolean;
  showRequired?: boolean;
  showOptional?: boolean;
  isPrivate?: boolean;
  showError?: boolean;
  helpText?: React.ReactNode;
  labelClassName?: string;
  children?: React.ReactNode;
};

export const FieldWrapper: React.FC<WrapperProps> = ({
  inputId,
  label,
  style,
  className,
  error,
  isHorizontal = false,
  showRequired = false,
  showOptional = false,
  isPrivate = false,
  showError = false,
  helpText,
  labelClassName,
  children,
}) => {
  const labelContent = label ? (
    <label
      className={clsx(
        'label',
        error && 'has-text-danger',
        isPrivate && 'is-private',
        showRequired && 'is-required',
        showOptional && 'is-optional',
        labelClassName,
      )}
      htmlFor={inputId}
    >
      {label}
    </label>
  ) : null;

  const controlContent = children;

  const helpContent = helpText ? <p className="help">{helpText}</p> : null;

  const errorMessage = getErrorMessage(error);
  const prevMessage = usePrevious(errorMessage);
  const errorContent = (
    <UnmountClosed isOpened={showError && !!errorMessage}>
      <p className="has-text-danger is-size-7" style={{ paddingTop: '0.25rem', margin: 0 }}>
        {errorMessage || prevMessage}
      </p>
    </UnmountClosed>
  );

  if (isHorizontal) {
    return (
      <div className={clsx('field is-horizontal', className)} style={style}>
        <div className="field-label is-normal">{labelContent}</div>
        <div className="field-body">
          <div className="field">
            {controlContent}
            {helpContent}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={clsx('field', className)} style={style}>
        {labelContent}
        {controlContent}
        {helpContent}
        {errorContent}
      </div>
    );
  }
};

export const NumberInput = (f: RenderProps) => {
  return (
    <input
      className={clsx('input', f.error && 'is-danger')}
      type="number"
      step={0.01}
      name={f.name}
      value={f.value ?? ''}
      onChange={(e) => f.onChange(parseNum(e.target.value))}
      onBlur={f.onBlur}
      id={f.inputId}
    />
  );
};

type RenderProps = Omit<ControllerRenderProps, 'ref'> & {
  error?: FieldError;
  inputId?: string;
  rules?: any;
};

type FieldProps = WrapperProps & {
  name: string;
  type?: 'number';
  inputClassName?: string;
  render?: (f: RenderProps) => React.ReactNode;
  as?: React.ElementType;
  required?: boolean;
  rules?: any;
  control?: Control<any>;
  isTextArea?: boolean;
  charProgressMode?: 'all' | 'error';
};

const Field: React.FC<FieldProps> = ({
  name,
  label,
  style,
  className,
  inputClassName,
  render,
  as: As,
  isHorizontal = false,
  required = false,
  showRequired = false,
  isPrivate = false,
  showError = false,
  rules = {},
  control,
  helpText,
  labelClassName,
  ...rest
}) => {
  const inputId = `${useSequentialId()}-form-input`;

  const { field, fieldState } = useController({
    control,
    name,
    rules: { required, ...rules },
  });

  const props: RenderProps = {
    name: field.name,
    onBlur: field.onBlur,
    onChange: field.onChange,
    value: field.value,
    error: fieldState.error,
    inputId,
    rules,
  };

  return (
    <FieldWrapper
      inputId={inputId}
      label={label}
      style={style}
      className={className}
      error={fieldState.error}
      isHorizontal={isHorizontal}
      showRequired={showRequired}
      showError={showError}
      isPrivate={isPrivate}
      helpText={helpText}
      labelClassName={labelClassName}
    >
      {As ? (
        <As {...rest} {...props} />
      ) : render ? (
        render(props)
      ) : rest.type === 'number' ? (
        <div className="control is-expanded">
          <NumberInput {...props} />
        </div>
      ) : (
        <div className="control is-expanded">
          {/* @ts-expect-error missing types */}
          <LimitedTextInput
            {...rest}
            {...props}
            className={inputClassName}
            charProgressMode={rest.charProgressMode !== undefined ? rest.charProgressMode : 'error'}
          />
        </div>
      )}
    </FieldWrapper>
  );
};
export default Field;

export const CheckboxField: React.FC<FieldProps> = (props) => {
  return (
    <Field
      {...props}
      render={(f) => (
        // @ts-expect-error missing types
        <Checkbox
          {...f}
          value={!!f.value}
          //
          onChange={(v: any) => f.onChange(!!v)}
        />
      )}
    />
  );
};
