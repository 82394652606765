import React from 'react';
import clsx from 'clsx';
import * as _ from 'lodash-es';

import { useSequentialId } from 'lib/utils/sequentialId';

const CHECKBOX_VARIANTS = {
  default: 'is-checkradio',
  switch: 'switch',
};

export const Checkbox = ({
  value,
  onChange,
  label,
  disabled,
  variant = 'default',
  inputClassName,
  fieldClassName,
}) => {
  const id = `${useSequentialId()}-checkbox`;

  return (
    <div className={clsx('field is-relative', fieldClassName)}>
      <input
        type="checkbox"
        className={clsx(CHECKBOX_VARIANTS[variant] || CHECKBOX_VARIANTS.default, inputClassName)}
        disabled={disabled}
        name={id}
        id={id}
        checked={!!value}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />{' '}
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export const Checkboxes = ({
  value,
  onChange,
  onChangeOption,
  options,
  disabled,
  variant = 'checkradio',
  inputClassName,
  fieldClassName,
}) => {
  const isArray = Array.isArray(value);

  return options.map(({ key, label, className }) => {
    return (
      <Checkbox
        key={key}
        disabled={disabled}
        fieldClassName={fieldClassName}
        inputClassName={clsx(inputClassName, className)}
        variant={variant}
        label={label}
        onChange={(checked) => {
          if (onChange) {
            if (isArray) onChange(checked ? _.union(value, [key]) : _.difference(value, [key]));
            else onChange({ ...value, [key]: checked });
          } else if (onChangeOption) {
            onChangeOption(key, checked);
          }
        }}
        value={isArray ? value.includes(key) : value?.[key] || false}
      />
    );
  });
};

export const Radio = ({ value, onChange, options, booleanOptions, disabled, inputClassName }) => {
  const seqId = useSequentialId();
  const name = `${seqId}-radio`;

  if (booleanOptions)
    options = [
      { key: true, label: booleanOptions[0] },
      { key: false, label: booleanOptions[1] },
    ];

  return options.map(({ key, label, className }) => {
    const id = `${name}-${key}`;

    return (
      <div key={id} className="field is-relative">
        <input
          type="radio"
          className={clsx('is-checkradio', className, inputClassName)}
          disabled={disabled}
          name={name}
          id={id}
          checked={value === key}
          onChange={(e) => e.target.checked && onChange(key)}
        />{' '}
        <label htmlFor={id}>{label}</label>
      </div>
    );
  });
};
