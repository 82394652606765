import React, { useState } from 'react';
import * as z from 'zod';

import Modal from 'components/Modal';
import LimitedTextInput from 'components/form/LimitedTextInput';
import { FieldWrapper } from 'components/form/Field';
import { toasts } from 'components/alert';
import { getDeviceUUID } from 'lib/tracking';
import { createInstallAttribution } from 'lib/db';
import { isValidEmail } from 'lib/utils/string';

const RefCodeModal = ({ active = false, onClose }) => {
  const [refCode, setRefCode] = useState('');
  const [saving, setSaving] = useState(false);

  const handleSaveCode = async () => {
    setSaving(true);

    const device_uuid = await getDeviceUUID();

    if (device_uuid) {
      try {
        await createInstallAttribution({ device_uuid, referral_code: refCode });
        toasts.success('Code successfully submitted');
        onClose();
      } catch (err) {
        toasts.error(err.message);
      }
    } else {
      toasts.error('Failed to identify your device');
    }

    setSaving(false);
  };

  return (
    <Modal
      active={active}
      loading={saving}
      header="Referral code"
      onConfirm={handleSaveCode}
      onClose={onClose}
      confirmDisabled={!isValidEmail(refCode) || saving}
      labels={{ confirm: 'Submit', cancel: 'Cancel' }}
    >
      <FieldWrapper label="Please enter your referral code">
        <LimitedTextInput
          onChange={(value) => setRefCode(value)}
          value={refCode}
          placeholder="ringo@vanly.app"
          autofocus
          type="email"
        />
      </FieldWrapper>
    </Modal>
  );
};

export default RefCodeModal;
